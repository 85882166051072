import { AiOutlineGoogle, AiOutlineTwitter } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';

const SocialLogin = ({ loading, onSigninWithGoogle, onSigninWithFacebook, onSigninWithTwitter }) => {
	return (
		<div className="h-full w-full px-4 dark:bg-[#080A0D] sm:px-6">
			<div className="mx-auto max-w-[650px] pt-[8%] text-center">
				<h1 className="mb-3 text-3xl font-bold">Create an account to get started</h1>
				<p className="hidden text-base dark:text-gray-200 sm:block">
					Join now and level up your edge by receiving our daily reports.
				</p>
			</div>

			<div className="mx-auto mt-6 flex max-w-[300px] flex-col items-center justify-between gap-5 md:flex-row md:gap-0">
				<div className="flex w-full flex-col gap-y-4 ">
					<button
						className="rounded bg-gradient-to-r from-cyan-500 to-blue-500 p-[1px]"
						onClick={onSigninWithGoogle}
						disabled={loading}
					>
						<div className="flex items-center gap-x-2 rounded bg-white px-3 py-[14px] transition-all hover:opacity-80 dark:bg-[#080a0d]">
							<AiOutlineGoogle size={18} />
							<span>Sign in with Google</span>
						</div>
					</button>
					<button disabled className="cursor-not-allowed rounded bg-gradient-to-r from-gray-700 to-gray-800 p-[1px]">
						<div className="flex items-center gap-x-2 rounded bg-white px-3 py-[14px] text-gray-400 transition-all dark:bg-[#080a0d]">
							<GrFacebookOption size={18} />
							<span>Sign in with Facebook</span>
						</div>
					</button>
					<button disabled className="cursor-not-allowed rounded bg-gradient-to-r from-gray-700 to-gray-800 p-[1px]">
						<div className="flex items-center gap-x-2 rounded bg-white px-3 py-[14px] text-gray-400 transition-all dark:bg-[#080a0d]">
							<AiOutlineTwitter size={18} />
							<span>Sign in with Twitter</span>
						</div>
					</button>
				</div>
			</div>

			{/* <div className="mt-10 text-center">
          <Link href={'/login'}>
            <a className="underline">
              Already have an account? <span className="font-bold">Login</span>
            </a>
          </Link>
        </div> */}
		</div>
		// </LayoutWrapper>
	);
};

export default SocialLogin;
