import { PACKAGE_PLANS } from '@/lib/constants';
import { dayjs } from '@/lib/formatDate';
import { SubscriptionPlan } from '../api/getSubscriptionPlan';

export const getCurrentSubscriptionPlan = (plans: SubscriptionPlan[] | null) => {
	let plan: SubscriptionPlan | null = null;

	if (plans && plans.length > 0) {
		const isActivePlan = PACKAGE_PLANS.some((id) => {
			const planInfo = plans?.find((s) => s.ticket_id === id);
			if (planInfo) {
				const isActive = dayjs(planInfo?.expire_at).isAfter(dayjs());

				if (isActive) plan = planInfo;

				return isActive;
			}

			return false;
		});

		if (!isActivePlan) {
			const sortedPlan = plans.sort((a, b) => {
				return dayjs(a.expire_at).isAfter(dayjs(b.expire_at)) ? -1 : 1;
			});

			plan = sortedPlan[0];
		}
	}

	return plan;
};
