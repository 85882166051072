export type UserProfile = any;

// This api check access token is valid or not
const getUserProfile = async (accessToken: string): Promise<UserProfile | null> => {
	try {
		if (!accessToken) {
			return null;
		}

		const url = new URL(`api/auth/local/callback`, process.env.NEXT_PUBLIC_BASE_BACKEND_URL);
		url.searchParams.append('access_token', accessToken);

		const res = await fetch(url.toString());

		if (res.ok) {
			const payload = await res.json();

			return payload?.user;
		}

		return null;
	} catch (error) {
		return null;
	}
};

export default getUserProfile;
