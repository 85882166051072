import Container from '@/components/layouts/Container';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { FC, useEffect, useState } from 'react';
import SocialLogin from './SocialLogin';
import { PREMIUM_PACKAGE_PLANS, SUBSCRIPTION_PLAN, SUPPORTED_REDIRECT } from '@/lib/constants';
import getSubscriptionPlan, { SubscriptionPlan } from './api/getSubscriptionPlan';
import { dayjs, formatDate } from '@/lib/formatDate';
import { activeTrial } from './api/activeTrial';
import { clearCookies } from '@/lib/cookies';
import Cookies from 'js-cookie';
import NProgress from 'nprogress';
import { getCurrentSubscriptionPlan } from './utils';
import { Theme, toast } from 'react-toastify';
import { useTheme } from 'next-themes';
import getUserProfile from './api/getUserProfile';

type Props = {
	providers: any;
};

export const LoginPage: FC<Props> = ({ providers }) => {
	const { t } = useTranslation(['common']);

	const googleProvider = providers?.google;
	const facebookProvider = providers?.facebook;
	const twitterProvider = providers?.twitter;

	const router = useRouter();
	const { theme = 'dark' } = useTheme();
	const { data: session, status } = useSession();
	const [isActivatingTrial, setActivatingTrial] = useState(false);

	const subscriptionPlanNotice = (plans: SubscriptionPlan[]) => {
		const currentPlan = getCurrentSubscriptionPlan(plans);

		if (currentPlan) {
			const isActivePlan = dayjs(currentPlan?.expire_at).isAfter(dayjs());
			const message = isActivePlan ? 'system:message.active_subscription' : 'system:message.expired_subscription';

			toast.info(t(message, { planName: currentPlan?.ticket_name }), {
				theme: theme as Theme,
			});
		}
	};

	const handleRedirect = async () => {
		const supportedRedirect = SUPPORTED_REDIRECT.find((s) => s.id === router.query.redirect);

		if (supportedRedirect) {
			const accessToken = (session as any)?.access_token;
			NProgress.start();
			setActivatingTrial(true);

			const userProfile = await getUserProfile(accessToken);

			if (!userProfile) {
				clearCookies();
				Cookies.remove('access-token', {
					path: '/',
					sameSite: 'Lax',
				});
				Cookies.remove('access-token', {
					path: '/',
					domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
					sameSite: 'Lax',
				});
				await signOut({ redirect: true });

				NProgress.done();
				setActivatingTrial(false);
				return;
			}

			const plans = userProfile?.subPackages;

			switch (supportedRedirect?.id) {
				case 'dashboard-registration': {
					let redirectUrl = supportedRedirect.url;

					if (plans && plans.length > 0) {
						let isExpiredPremiumPlan = false;
						const isActivePremiumPlan = PREMIUM_PACKAGE_PLANS.some((planId) => {
							const planInfo = plans.find((p) => p.ticket_id === planId);

							if (planInfo) {
								const isActive = dayjs(planInfo.expire_at).isAfter(dayjs());
								isExpiredPremiumPlan = !isActive;

								return isActive;
							}

							return false;
						});

						if (isActivePremiumPlan) {
							redirectUrl = '/account/overview';
							subscriptionPlanNotice(plans);
						} else if (isExpiredPremiumPlan) {
							redirectUrl = `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/dashboard`;
						}
					}

					router.push(redirectUrl);
					break;
				}
				case 'dashboard-trial': {
					if (plans && plans.length > 0) {
						subscriptionPlanNotice(plans);
						return router.push('/account/overview');
					}

					const isSuccess = await activeTrial(accessToken);

					// Valid old access-token. if cannot active trial by this token, force user to login again
					if (isSuccess) {
						router.push(`${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/dashboard`);
					} else {
						clearCookies();
						Cookies.remove('access-token', {
							path: '/',
							sameSite: 'Lax',
						});
						Cookies.remove('access-token', {
							path: '/',
							domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
							sameSite: 'Lax',
						});

						await signOut({ redirect: true });

						NProgress.done();
						setActivatingTrial(false);
					}
					break;
				}
				default:
					router.push(supportedRedirect?.url as string);
					break;
			}
		} else {
			router.push('/');
		}
	};

	useEffect(() => {
		if (router.isReady) {
			if (router.query.datafi_coupon) {
				localStorage.setItem('datafi_coupon', router.query.datafi_coupon ? (router.query.datafi_coupon as string) : '');
			}

			if (session) {
				handleRedirect();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session, router.isReady]);

	return (
		<Container title={t('common:header.blog') as string}>
			<div className="flex h-[calc(100vh-134px)] flex-col items-center justify-center">
				<SocialLogin
					loading={status === 'loading' || isActivatingTrial}
					onSigninWithGoogle={() =>
						signIn(googleProvider?.id, {
							redirect: false,
							callbackUrl: `${router.query.callbackUrl ? router.query.callbackUrl : window.location.href}`,
						})
					}
					onSigninWithFacebook={() => signIn(facebookProvider?.id)}
					onSigninWithTwitter={() => signIn(twitterProvider?.id)}
				/>
			</div>
		</Container>
	);
};
