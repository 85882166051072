/* eslint-disable react-hooks/rules-of-hooks */
import type { InferGetServerSidePropsType } from 'next';
import { getCsrfToken, getProviders } from 'next-auth/react';

import { getServerSideTranslations } from '@/lib/i18n';
import { LoginPage } from '@/modules/auth/LoginPage';

export default function Login(_props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	return <LoginPage providers={_props.providers} />;
}

export async function getServerSideProps(context) {
	const { locale } = context;

	const providers = await getProviders();
	const inlinedTranslations = await getServerSideTranslations(locale);

	const csrfToken = (await getCsrfToken(context).catch(console.error)) || '';

	return {
		props: {
			providers,
			csrfToken,
			locale,
			...inlinedTranslations,
		},
	};
}
