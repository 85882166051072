export async function activeTrial(accessToken: string) {
	try {
		const url = new URL(`/api/subscriptions/trial`, process.env.NEXT_PUBLIC_BASE_BACKEND_URL);

		const res = await fetch(url.toString(), {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		return res.ok;
	} catch (error) {
		return false;
	}
}
